import { Fragment, useState } from "react";

import { Box, Typography, Button } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { Stack } from "@mui/system";
import { InputAdornment } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const StandardForm = ({
  title,
  subtext,
  submitFunction,
  formData,
  role,
  setRole,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleChange = (event) => {
    setRole(event.target.value);
  };

  return (
    <Fragment>
      {title ? title : null}

      {subtext}

      <Box>
        <form onSubmit={submitFunction}>
          <Stack mb={1}>
            {formData.map((item) => (
              <Fragment key={item.id}>
                <Typography
                  variant="subtitle1"
                  color="secondary"
                  fontWeight={600}
                  component="label"
                  htmlFor={item.name}
                  mb="5px"
                  mt="15px"
                  sx={{
                    fontFamily: "Roboto Slab, serif",
                  }}
                >
                  {item.label} {item.required ? "*" : ""}
                </Typography>

                {item.type === "select" ? (
                  <Fragment>
                    <Select
                      labelId="simple-select-label"
                      color="secondary"
                      id={item.name}
                      value={role}
                      onChange={handleChange}
                      required
                    >
                      <MenuItem value={1527}>Vendedor</MenuItem>
                      <MenuItem value={1685}>Promotor</MenuItem>
                      <MenuItem value={1894}>Administrador</MenuItem>
                    </Select>
                  </Fragment>
                ) : (
                  <TextField
                    id={item.name}
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    type={
                      item.type === "password"
                        ? showPassword
                          ? "text"
                          : "password"
                        : "text"
                    }
                    placeholder={item.placeholder}
                    required={item.required}
                    InputProps={{
                      endAdornment: item.endIcon ? (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ) : null,
                    }}
                  />
                )}
              </Fragment>
            ))}
          </Stack>
          <Button
            type="submit"
            color="secondary"
            variant="contained"
            size="large"
            sx={{
              marginTop: "25px",
            }}
            fullWidth
          >
            Agregar
          </Button>
        </form>
      </Box>
    </Fragment>
  );
};

export { StandardForm };
