import { Fragment, useState, useEffect, useContext } from "react";

import { Navbar } from "../../Components/Navbar";
import { StandardForm } from "./StandardForm";
import { Carousel } from "./Carousel";
import { AppContext } from "../../Pages/AuthContext";
import { decodeJWT } from "../../Components/Utilities";

import { Grid, Box, Card, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

import Swal from "sweetalert2";

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: "#9c27b0",
  fontWeight: "bold",
  marginBottom: theme.spacing(4),
  textAlign: "center",
  textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
}));

const AddUser = () => {
  const { userData } = useContext(AppContext);
  const [users, setUsers] = useState([]);
  const [role, setRole] = useState("");

  const FORM_DATA = [
    {
      id: 1,
      name: "name",
      label: "Nombre",
      type: "text",
      placeholder: "Nombre completo",
      multiline: false,
      required: true,
      endIcon: false,
    },
    {
      id: 2,
      name: "email",
      label: "Email",
      type: "email",
      placeholder: "nombre.apellido@dominio.com",
      multiline: false,
      required: true,
      endIcon: false,
    },
    {
      id: 3,
      name: "password",
      label: "Contraseña",
      type: "password",
      placeholder: "Escriba una contraseña",
      multiline: false,
      required: true,
      endIcon: true,
    },
    {
      id: 4,
      name: "password_confirmation",
      label: "Confirmar contraseña",
      type: "password",
      placeholder: "Escribe la misma contraseña",
      multiline: false,
      required: true,
      endIcon: true,
    },
    {
      id: 5,
      name: "role",
      label: "Rol",
      type: "select",
      placeholder: "Selecciona un rol",
      multiline: false,
      required: true,
      endIcon: false,
    },
  ];

  const payload = decodeJWT(userData.access_token);

  const getAllUsers = async () => {
    await fetch(`${import.meta.env.VITE_API_URL}/user/`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${userData.access_token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setUsers(data);
      })
      .catch((err) => {
        Swal.fire({
          title: "Error",
          icon: "error",
        });
      });
  };

  const addUser = async (event) => {
    event.preventDefault();

    const user_email = payload.email;
    let name = document.getElementById("name");
    let email = document.getElementById("email");
    let password = document.getElementById("password");
    let password_conf = document.getElementById("password_confirmation");

    if (password.value !== password_conf.value) {
      Swal.fire({
        title: "Error",
        text: "Las contraseñas no coinciden",
        icon: "error",
      });
      return;
    }

    await fetch(`${import.meta.env.VITE_API_URL}/user/`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${userData.access_token}`,
      },
      body: JSON.stringify({
        user_email,
        name: name.value,
        email: email.value,
        password: password.value,
        role,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        name.value = "";
        email.value = "";
        password.value = "";
        password_conf.value = "";
        setRole("");
        setUsers([...users, data.created_user]);
        Swal.fire({
          title: "Usuario creado",
          icon: "success",
        });
      })
      .catch((err) => {
        Swal.fire({
          title: "Error",
          icon: "error",
        });
      });
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  return (
    <Fragment>
      <Navbar />

      <Box
        sx={{
          position: "relative",
          "&:before": {
            content: '""',
            background: "radial-gradient(#d2f1df, #d3d7fa, #bad8f4)",
            backgroundSize: "400% 400%",
            animation: "gradient 15s ease infinite",
            position: "absolute",
            height: "100%",
            width: "100%",
            opacity: "0.3",
          },
        }}
      >
        <Grid
          container
          spacing={0}
          justifyContent="center"
          sx={{ height: "93vh" }}
        >
          <Grid item xs={8}>
            <Carousel
              elements={users}
              setElements={setUsers}
              userData={userData}
              userEmail={payload.email}
            />
          </Grid>
          <Grid
            item
            xs={4}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Card
              elevation={9}
              sx={{ p: 4, zIndex: 1, width: "100%", maxWidth: "500px" }}
            >
              <StandardForm
                title={
                  <StyledTypography
                    variant="h3"
                    sx={{
                      fontFamily: "Roboto Slab, serif",
                    }}
                  >
                    Nuevo usuario
                  </StyledTypography>
                }
                subtext={
                  <Typography
                    variant="subtitle1"
                    textAlign="center"
                    color="textSecondary"
                    mb={1}
                  >
                    Usuarios del sistema CRM
                  </Typography>
                }
                submitFunction={addUser}
                formData={FORM_DATA}
                role={role}
                setRole={setRole}
              />
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

export { AddUser };
