import React from "react";

const useSessionStorage = (itemName, initialValue) => {
  const [item, setItem] = React.useState(initialValue);

  React.useEffect(() => {
    try {
      const sessionStorageItem = sessionStorage.getItem(itemName);
      let parsedItem;

      if (!sessionStorageItem) {
        sessionStorage.setItem(itemName, JSON.stringify(initialValue));
        parsedItem = initialValue;
      } else {
        parsedItem = JSON.parse(sessionStorageItem);
        setItem(parsedItem);
      }
    } catch (error) {
      Swal.fire({
        title: "Error",
        icon: "error",
      });
    }
  }, []);

  const saveItem = (newItem) => {
    sessionStorage.setItem(itemName, JSON.stringify(newItem));
    setItem(newItem);
  };

  const deleteItem = () => {
    sessionStorage.removeItem(itemName);
    setItem({});
  };

  return {
    item,
    saveItem,
    deleteItem,
  };
};

export { useSessionStorage };
