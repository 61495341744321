import { Fragment, useState } from "react";

import { UserCard } from "./UserCard";

import { Grid, Stack, Button, Box } from "@mui/material";

const Carousel = ({ elements, setElements, userData, userEmail }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 3;

  const handleNext = () => {
    if (currentPage < Math.floor(elements.length / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevious = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const currentItems = elements.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  return (
    <Fragment>
      <div style={{ height: "76%", padding: "0 0 0 96px" }}>
        <Grid container spacing={2} columns={12} marginTop={7}>
          {currentItems.map((user) => (
            <Grid item xs={4} key={user.id}>
              <UserCard
                data={user}
                userData={userData}
                userEmail={userEmail}
                users={elements}
                setUsers={setElements}
              />
            </Grid>
          ))}
        </Grid>
      </div>
      <Box mt={6}>
        <Stack direction="row" spacing={2} marginLeft={"38%"}>
          <Button
            variant="contained"
            color="secondary"
            onClick={handlePrevious}
            disabled={currentPage === 0}
          >
            Anterior
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleNext}
            disabled={
              currentPage >= Math.floor(elements.length / itemsPerPage) ||
              (elements.length % itemsPerPage === 0 &&
                currentPage === Math.floor(elements.length / itemsPerPage) - 1)
            }
          >
            Siguiente
          </Button>
        </Stack>
      </Box>
    </Fragment>
  );
};

export { Carousel };
