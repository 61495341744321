import { Fragment, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";

const phoneNumberAutoFormat = (phoneNumber) => {
  const number = phoneNumber.trim().replace(/[^0-9]/g, "");

  if (number.length < 4) return number;
  if (number.length < 7) return number.replace(/(\d{3})(\d{1})/, "$1-$2");
  if (number.length < 11)
    return number.replace(/(\d{3})(\d{3})(\d{1})/, "$1-$2-$3");
  return number.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
};

const PhoneNumber = ({
  isDisabled = false,
  initialState = false,
  setValueWrapper = false,
  fieldLabel,
  fieldName,
  savedValue = false,
  size = false,
  required = false,
}) => {
  const [value, setValue] = useState(savedValue ? savedValue : "");

  const onChange = (e) => {
    const targetValue = phoneNumberAutoFormat(e.target.value);
    setValue(targetValue);
    if (initialState) {
      setValueWrapper({
        ...initialState,
        [fieldName]: targetValue,
      });
    }
  };

  useEffect(() => {
    if (savedValue) {
      setValue(savedValue);
    } else {
      setValue("");
    }
  }, [savedValue]);

  return (
    <Fragment>
      <Box>
        <TextField
          disabled={isDisabled}
          type="tel"
          required={required}
          size={size ? size : "normal"}
          value={value}
          onChange={onChange}
          fullWidth
          margin="dense"
          label={fieldLabel}
          name={fieldName}
          variant="outlined"
          color="secondary"
          inputProps={{ maxLength: 12 }}
        />
      </Box>
    </Fragment>
  );
};

export { PhoneNumber };
