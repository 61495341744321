import { Fragment, useState, useEffect, useContext } from "react";

import Swal from "sweetalert2";

import { Navbar } from "../../Components/Navbar";
import { StandardForm } from "./StandardForm";
import { Carousel } from "./Carousel";
import { AppContext } from "../../Pages/AuthContext";
import { decodeJWT } from "../../Components/Utilities";

import { Grid, Box, Card, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: "#9c27b0",
  fontWeight: "bold",
  marginBottom: theme.spacing(4),
  textAlign: "center",
  textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
}));

const FORM_DATA = [
  {
    name: "course_name",
    label: "Nombre del curso",
    placeholder: "Nombre del curso",
    isDate: false,
    isSelect: false,
    multiline: false,
    required: true,
  },
  {
    name: "course_date",
    label: "Fecha del curso",
    placeholder: "Día / Mes / Año",
    isDate: true,
    isSelect: false,
    multiline: false,
    required: true,
  },
  {
    name: "course_duration",
    label: "Duración del curso",
    placeholder: "Seleccione una duración",
    isDate: false,
    isSelect: true,
    multiline: false,
    required: true,
  },
  {
    name: "early_payment_date",
    label: "Fecha de pronto pago",
    placeholder: "Día / Mes / Año",
    isDate: true,
    isSelect: false,
    multiline: false,
    required: true,
  },
  {
    name: "modality",
    label: "Modalidad",
    placeholder: "Seleccione una modalidad",
    isDate: false,
    isSelect: true,
    multiline: false,
    required: true,
  },
  {
    name: "responsible_seller",
    label: "Vendedor responsable del curso",
    placeholder: "Seleccione un vendedor",
    isDate: false,
    isSelect: true,
    multiline: false,
    required: true,
  },
];

const AddCourse = () => {
  const { userData } = useContext(AppContext);
  const [courses, setCourses] = useState([]);
  const [sellers, setSellers] = useState([]);

  const payload = decodeJWT(userData.access_token);

  const get_all_courses = async () => {
    await fetch(`${import.meta.env.VITE_API_URL}/course/get_courses`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${userData.access_token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setCourses(data);
      })
      .catch((err) => {
        Swal.fire({
          title: "Error",
          icon: "error",
        });
      });
  };

  const get_all_sellers = async () => {
    await fetch(`${import.meta.env.VITE_API_URL}/user/sellers`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${userData.access_token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setSellers(data);
      })
      .catch((err) => {
        Swal.fire({
          title: "Error",
          icon: "error",
        });
      });
  };

  useEffect(() => {
    get_all_courses();
    get_all_sellers();
  }, []);

  return (
    <Fragment>
      <Navbar />
      <Box
        sx={{
          position: "relative",
          "&:before": {
            content: '""',
            background: "radial-gradient(#d2f1df, #d3d7fa, #bad8f4)",
            backgroundSize: "400% 400%",
            animation: "gradient 15s ease infinite",
            position: "absolute",
            height: "100%",
            width: "100%",
            opacity: "0.3",
          },
        }}
      >
        <Grid
          container
          spacing={0}
          justifyContent="center"
          sx={{ height: "93vh" }}
        >
          <Grid
            item
            xs={4}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Card
              elevation={9}
              sx={{ p: "0 32px", zIndex: 1, width: "100%", maxWidth: "500px" }}
            >
              <StandardForm
                title={
                  <StyledTypography
                    variant="h3"
                    sx={{
                      fontFamily: "Roboto Slab, serif",
                      mb: 1,
                    }}
                  >
                    Nuevo curso
                  </StyledTypography>
                }
                subtext={
                  <Typography
                    variant="subtitle1"
                    textAlign="center"
                    color="textSecondary"
                    mb={1}
                  >
                    Cursos de Industrial Seminars
                  </Typography>
                }
                sellerUsers={sellers}
                formData={FORM_DATA}
                courses={courses}
                setCourses={setCourses}
                userData={userData}
                userEmail={payload.email}
              />
            </Card>
          </Grid>
          <Grid item xs={8}>
            <Carousel
              elements={courses}
              setElements={setCourses}
              userData={userData}
              userEmail={payload.email}
              formData={FORM_DATA}
              sellerUsers={sellers}
            />
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

export { AddCourse };
