import { Fragment, useState } from "react";

import Swal from "sweetalert2";

import { Box, Typography, Button } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { Stack } from "@mui/system";

const buildSelectOptions = (fieldName, optionsData) => {
  let options = [];

  switch (fieldName) {
    case "course_duration":
      options = optionsData["duration_options"];
      break;
    case "modality":
      options = optionsData["modality_options"];
      break;
    case "responsible_seller":
      options = optionsData["seller_options"];
      break;
  }

  return options.map((option) => (
    <MenuItem key={option.id} value={option.value}>
      {option.label}
    </MenuItem>
  ));
};

const durations = [
  "",
  "1 día",
  "2 días",
  "3 días",
  "4 días",
  "5 días",
  "6 días",
  "7 días",
];

let durationOptions = durations.map((duration, index) => ({
  id: index,
  value: duration,
  label: duration,
}));

const modalities = [
  "",
  "Online",
  "Presencial",
  "Híbrido",
  "In Company",
  "Póliza",
];

let modalityOptions = modalities.map((modality, index) => ({
  id: index,
  value: modality,
  label: modality,
}));

const StandardForm = ({
  title,
  subtext,
  sellerUsers,
  formData,
  courses,
  setCourses,
  userData,
  userEmail,
}) => {
  const [courseDate, setCourseDate] = useState(null);
  const [earlyPaymentDate, setEarlyPaymentDate] = useState(null);
  const [courseDuration, setCourseDuration] = useState("");
  const [courseModality, setCourseModality] = useState("");
  const [responsibleSeller, setResponsibleSeller] = useState("");

  const chooseDefaultValue = (fieldName) => {
    switch (fieldName) {
      case "course_duration":
        return courseDuration;
      case "modality":
        return courseModality;
      case "responsible_seller":
        return responsibleSeller;
    }
  };

  const setNewValue = (fieldName, newValue) => {
    switch (fieldName) {
      case "course_duration":
        setCourseDuration(newValue);
        break;
      case "modality":
        setCourseModality(newValue);
        break;
      case "responsible_seller":
        setResponsibleSeller(newValue);
        break;
    }
  };

  const CourseDatePicker = () => {
    return (
      <DesktopDatePicker
        disablePast
        value={courseDate}
        onChange={(newValue) => setCourseDate(newValue)}
        format="DD/MM/YYYY"
        label={"Día / Mes / Año"}
        views={["year", "month", "day"]}
        slots={{
          textField: (props) => <TextField color="secondary" {...props} />,
        }}
      />
    );
  };

  const EarlyDatePicker = () => {
    return (
      <DesktopDatePicker
        disablePast
        value={earlyPaymentDate}
        onChange={(newValue) => setEarlyPaymentDate(newValue)}
        format="DD/MM/YYYY"
        label={"Día / Mes / Año"}
        views={["year", "month", "day"]}
        slots={{
          textField: (props) => <TextField color="secondary" {...props} />,
        }}
      />
    );
  };

  const courseFields = [
    "course_duration",
    "course_name",
    "modality",
    "responsible_seller",
  ];

  const addCourse = async (event) => {
    event.preventDefault();

    let bodyRequest = {};

    const formData = new FormData(event.currentTarget);
    const formJson = Object.fromEntries(formData.entries());

    bodyRequest["creator_user"] = userEmail;
    bodyRequest["course_date"] = courseDate.toISOString();
    bodyRequest["early_payment_date"] = earlyPaymentDate.toISOString();
    courseFields.forEach((field) => {
      bodyRequest[field] = formJson[field];
    });

    await fetch(`${import.meta.env.VITE_API_URL}/course/new_course`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${userData.access_token}`,
      },
      body: JSON.stringify(bodyRequest),
    })
      .then((response) => response.json())
      .then((data) => {
        setCourseDate(null);
        setEarlyPaymentDate(null);
        document.getElementById("course_name").value = "";
        setCourseDuration("");
        setCourseModality("");
        setResponsibleSeller("");

        setCourses([...courses, data.created_course]);
        Swal.fire({
          title: "Curso creado",
          icon: "success",
        });
      })
      .catch((err) => {
        Swal.fire({
          title: "Error",
          icon: "error",
        });
      });
  };

  return (
    <Fragment>
      {title ? title : null}

      {subtext}

      <Box>
        <form onSubmit={addCourse}>
          <Stack mb={2}>
            {formData.map((field, index) => (
              <Fragment key={index}>
                <Typography
                  variant="subtitle1"
                  color="secondary"
                  fontWeight={600}
                  component="label"
                  htmlFor={field.name}
                  mb="5px"
                  mt={index === 0 ? "0" : "20px"}
                  sx={{
                    fontFamily: "Roboto Slab, serif",
                  }}
                >
                  {field.label} {field.required ? "*" : ""}
                </Typography>
                {field.isDate && field.name === "course_date" ? (
                  <CourseDatePicker />
                ) : field.isDate && field.name === "early_payment_date" ? (
                  <EarlyDatePicker />
                ) : field.isSelect ? (
                  <TextField
                    select
                    id={field.name}
                    name={field.name}
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    value={chooseDefaultValue(field.name)}
                    onChange={(event) =>
                      setNewValue(field.name, event.target.value)
                    }
                    label={field.placeholder}
                    required={field.required}
                    margin="dense"
                  >
                    {buildSelectOptions(field.name, {
                      duration_options: durationOptions,
                      modality_options: modalityOptions,
                      seller_options: sellerUsers,
                    })}
                  </TextField>
                ) : (
                  <TextField
                    id={field.name}
                    name={field.name}
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    placeholder={field.placeholder}
                    multiline={field.multiline}
                    rows={field.multiline ? 3 : 1}
                    required={field.required}
                  />
                )}
              </Fragment>
            ))}
          </Stack>
          <Button
            type="submit"
            color="secondary"
            variant="contained"
            size="large"
            fullWidth
            sx={{
              mt: 1,
              mb: 3,
            }}
          >
            Agregar
          </Button>
        </form>
      </Box>
    </Fragment>
  );
};

export { StandardForm };
