import { useContext, useState } from "react";
import { useRoutes, Navigate } from "react-router-dom";

import { Login } from "../Login";
import { Home } from "../Home";
import { AddCourse } from "../Courses";
import { AddUser } from "../Users";
import { NotFound } from "../NotFound";

import { AppContext } from "../AuthContext";
import { decodeJWT } from "../../Components/Utilities";

const AppRoutes = () => {
  const { userData } = useContext(AppContext);

  let payload;
  let isAuthenticated;
  let userEmail;
  let isAdmin;
  let isSeller;

  if (userData.hasOwnProperty("access_token")) {
    payload = decodeJWT(userData.access_token);
    isAuthenticated = payload.role === 1527 || payload.role === 1894;
    userEmail = payload.email;
    isAdmin = payload.role === 1894;
    isSeller = payload.role === 1527;
  }

  let routesForThisUser = [
    {
      path: "/",
      element: isAuthenticated ? (
        <Navigate to="/home" replace={true} />
      ) : (
        <Login />
      ),
    },
    { path: "/*", element: <NotFound /> },
  ];

  if (isAuthenticated && (isAdmin || isSeller)) {
    routesForThisUser.push({ path: "/home", element: <Home /> });
    routesForThisUser.push({ path: "/add-course", element: <AddCourse /> });
    routesForThisUser.push({ path: "/add-user", element: <AddUser /> });
  }

  let routes = useRoutes(routesForThisUser);
  return routes;
};

export { AppRoutes };
