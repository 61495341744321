import { useContext, useState, useEffect, Fragment } from "react";

import { AppContext } from "../../Pages/AuthContext";
import { Navbar } from "../../Components/Navbar";
import { decodeJWT } from "../../Components/Utilities";
import { EnhancedTable } from "./EnhancedTable";
import { PhoneNumber } from "./PhoneNumber";

import Swal from "sweetalert2";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import EditNoteIcon from "@mui/icons-material/EditNote";
import SwitchAccessShortcutIcon from "@mui/icons-material/SwitchAccessShortcut";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from "@mui/material/styles";
import { IconButton } from "@mui/material";
import { red } from "@mui/material/colors";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const StyledDiv = styled("div")((props) => ({
  height: props.height,
}));

const StyledPaper = styled(Paper)((props) => ({
  boxShadow: "unset",
}));

const DISCARDED = "Descartado";
const STAGE = "stage";
const ADMIN = "1894";

const headerNames = [
  "Estatus",
  "Etapa",
  "Pax",
  "Empresa",
  "Nombre completo",
  "Puesto",
  "Email principal",
  "Emails adicionales",
  "Acciones",
];

let headCells = [];
headerNames.forEach((name, index) => {
  headCells.push({ id: index, name: name });
});

const registerFieldsSettings = [
  {
    label: "Título",
    required: false,
    name: "title",
    multiline: false,
    isSelect: true,
    type: "text",
  },
  {
    label: "Nombre completo",
    required: true,
    name: "full_name",
    multiline: false,
    isSelect: false,
    type: "text",
  },
  {
    label: "Email principal",
    required: true,
    name: "main_email",
    multiline: false,
    isSelect: false,
    type: "email",
  },
  {
    label: "Emails adicionales",
    required: false,
    name: "additional_emails",
    multiline: true,
    isSelect: false,
    type: "text",
  },
  {
    label: "Etapa",
    required: false,
    name: "stage",
    multiline: false,
    isSelect: true,
    type: "text",
  },
  {
    label: "Interés",
    required: false,
    name: "interes",
    multiline: false,
    isSelect: true,
    type: "text",
  },
  {
    label: "Origen",
    required: true,
    name: "origin",
    multiline: false,
    isSelect: true,
    type: "text",
  },
  {
    label: "Medio del origen",
    required: true,
    name: "origin_media",
    multiline: false,
    isSelect: true,
    type: "text",
  },
  {
    label: "Cursos relacionados",
    required: false,
    name: "related_courses",
    multiline: false,
    isSelect: true,
    type: "text",
  },
  {
    label: "Empresa",
    required: true,
    name: "company",
    multiline: false,
    isSelect: false,
    type: "text",
  },
  {
    label: "Puesto",
    required: false,
    name: "position",
    multiline: false,
    isSelect: false,
    type: "text",
  },
  {
    label: "Celular",
    required: false,
    name: "cell_phone",
    multiline: false,
    isSelect: false,
    type: "tel",
  },
  {
    label: "Teléfono",
    required: false,
    name: "telephone",
    multiline: false,
    isSelect: false,
    type: "tel",
  },
];

const titles = [
  "",
  "Sr.",
  "Sra.",
  "Srta.",
  "Lic.",
  "Ing.",
  "Arq.",
  "Dr.",
  "Dra.",
  "Mtro.",
  "Mtra.",
];

let titleOptions = [];
titles.forEach((title, index) => {
  titleOptions.push({ id: index, value: title, label: title });
});

const originMedia = [
  "",
  "Servidor",
  "Plataforma",
  "WhatsApp",
  "Teléfono",
  "Historial",
];

let originMediaOptions = [];
originMedia.forEach((media, index) => {
  originMediaOptions.push({ id: index, value: media, label: media });
});

const interesOptions = ["", "Alto", "Medio", "Bajo"];

let interesOptionsData = [];
interesOptions.forEach((option, index) => {
  interesOptionsData.push({ id: index, value: option, label: option });
});

const stageOptions = [
  "Nueva solicitud",
  "PDF enviado",
  "Recepción del PDF confirmada",
  "Llamada exitosa",
  "En autorización",
  "Inscripción",
  "Descartado",
];

let stageOptionsData = [];
stageOptions.forEach((option, index) => {
  stageOptionsData.push({ id: index, value: option, label: option });
});

let registerFields = [];
registerFieldsSettings.forEach((field, index) => {
  registerFields.push({
    id: index,
    label: field.label,
    required: field.required,
    name: field.name,
    multiline: field.multiline,
    isSelect: field.isSelect,
    type: field.type,
  });
});

const paymentMethods = [
  "01-Efectivo",
  "02-Cheque nominativo",
  "03-Transferencia electrónica de fondos",
  "04-Tarjeta de crédito",
  "99-Por definir",
];
let paymentMethodsOptions = [];
paymentMethods.forEach((method, index) => {
  paymentMethodsOptions.push({ id: index, value: method, label: method });
});

const paymentTypes = [
  "PUE - Pago en una sola exhibición",
  "PPD - Pago en parcialidades o diferido",
];
let paymentTypesOptions = [];
paymentTypes.forEach((type, index) => {
  paymentTypesOptions.push({ id: index, value: type, label: type });
});

const cfdiUsages = [
  "G02 - Devoluciones, descuentos o bonificaciones",
  "G03 - Gastos en general",
  "D04 - Donativos",
  "P01 - Por definir",
];
let cfdiUsagesOptions = [];
cfdiUsages.forEach((usage, index) => {
  cfdiUsagesOptions.push({ id: index, value: usage, label: usage });
});

// Datos de Facturación
const billingFields = [
  {
    label: "Razón social",
    required: true,
    name: "legal_name",
    multiline: false,
    isSelect: false,
    type: "text",
    gridSize: 4,
  },
  {
    label: "RFC",
    required: true,
    name: "rfc",
    multiline: false,
    isSelect: false,
    type: "text",
    gridSize: 2,
  },
  {
    label: "País",
    required: true,
    name: "country",
    multiline: false,
    isSelect: false,
    type: "text",
    gridSize: 3,
  },
  {
    label: "Estado",
    required: true,
    name: "state",
    multiline: false,
    isSelect: false,
    type: "text",
    gridSize: 3,
  },
  {
    label: "Ciudad",
    required: true,
    name: "city",
    multiline: false,
    isSelect: false,
    type: "text",
    gridSize: 4,
  },
  {
    label: "Delegación/Municipio",
    required: true,
    name: "municipality",
    multiline: false,
    isSelect: false,
    type: "text",
    gridSize: 4,
  },
  {
    label: "Domicilio",
    required: true,
    name: "address",
    multiline: false,
    isSelect: false,
    type: "text",
    gridSize: 4,
  },
  {
    label: "Colonia",
    required: true,
    name: "colony",
    multiline: false,
    isSelect: false,
    type: "text",
    gridSize: 3,
  },
  {
    label: "Código postal",
    required: true,
    name: "zip_code",
    multiline: false,
    isSelect: false,
    type: "text",
    gridSize: 3,
  },
  {
    label: "Teléfono 1",
    required: true,
    name: "phone1",
    multiline: false,
    isSelect: false,
    type: "tel",
    gridSize: 3,
  },
  {
    label: "Teléfono 2",
    required: false,
    name: "phone2",
    multiline: false,
    isSelect: false,
    type: "tel",
    gridSize: 3,
  },
  {
    label: "Forma de pago",
    required: true,
    name: "payment_method",
    multiline: false,
    isSelect: true,
    type: "text",
    gridSize: 4,
    options: paymentMethodsOptions,
  },
  {
    label: "Método de pago",
    required: true,
    name: "payment_type",
    multiline: false,
    isSelect: true,
    type: "text",
    gridSize: 4,
    options: paymentTypesOptions,
  },
  {
    label: "Uso de CFDI",
    required: true,
    name: "cfdi_use",
    multiline: false,
    isSelect: true,
    type: "text",
    gridSize: 4,
    options: cfdiUsagesOptions,
  },
  {
    label: "Régimen fiscal",
    required: true,
    name: "fiscal_regime",
    multiline: false,
    isSelect: false,
    type: "text",
    gridSize: 6,
  },
  {
    label: "Fecha de compromiso de pago",
    required: true,
    name: "payment_commitment_date",
    multiline: false,
    isSelect: false,
    type: "date",
    gridSize: 6,
  },
];

// Contacto de seguimiento
const followUpFields = [
  {
    label: "Nombre del contacto",
    required: true,
    name: "contact_name",
    multiline: false,
    isSelect: false,
    type: "text",
    gridSize: 4,
  },
  {
    label: "Puesto",
    required: true,
    name: "contact_position",
    multiline: false,
    isSelect: false,
    type: "text",
    gridSize: 4,
  },
  {
    label: "E-mail",
    required: true,
    name: "contact_email",
    multiline: false,
    isSelect: false,
    type: "email",
    gridSize: 4,
  },
  {
    label: "Teléfono",
    required: true,
    name: "contact_phone",
    multiline: false,
    isSelect: false,
    type: "tel",
    gridSize: 4,
  },
  {
    label: "Celular",
    required: true,
    name: "contact_cell_phone",
    multiline: false,
    isSelect: false,
    type: "tel",
    gridSize: 4,
  },
];

// Autorizacion
const authorizationFields = [
  {
    label: "Nombre completo",
    required: true,
    name: "authorization_full_name",
    multiline: false,
    isSelect: false,
    type: "text",
    gridSize: 4,
  },
  {
    label: "Puesto",
    required: true,
    name: "authorization_position",
    multiline: false,
    isSelect: false,
    type: "text",
    gridSize: 4,
  },
  {
    label: "E-mail",
    required: true,
    name: "authorization_email",
    multiline: false,
    isSelect: false,
    type: "email",
    gridSize: 4,
  },
];

// Participants
const participantFields = [
  {
    label: "Título/Nombre",
    required: true,
    name: "full_name",
    multiline: false,
    isSelect: false,
    type: "text",
    gridSize: 4,
  },
  {
    label: "Puesto",
    required: true,
    name: "position",
    multiline: false,
    isSelect: false,
    type: "text",
    gridSize: 4,
  },
  {
    label: "E-mail",
    required: true,
    name: "email",
    multiline: false,
    isSelect: false,
    type: "email",
    gridSize: 4,
  },
  {
    label: "Sede",
    required: true,
    name: "headquarters",
    multiline: false,
    isSelect: false,
    type: "text",
    gridSize: 4,
  },
  {
    label: "Seminario",
    required: true,
    name: "seminar",
    multiline: false,
    isSelect: false,
    type: "text",
    gridSize: 4,
  },
];

const get_all_courses = async (setCourses, userData) => {
  await fetch(`${import.meta.env.VITE_API_URL}/course/get_courses`, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${userData.access_token}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      setCourses(data);
    })
    .catch((err) => {
      Swal.fire({
        title: "Error",
        icon: "error",
      });
    });
};

const get_company_data = async (
  companyId,
  setCompanyData,
  setParticipantValues,
  setPhoneData,
  setSameFollowUpContactData,
  userData
) => {
  await fetch(`${import.meta.env.VITE_API_URL}/company/${companyId}`, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${userData.access_token}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      setCompanyData(data);
      setPhoneData({
        phone1: data.billing_details.phone1,
        phone2: data.billing_details.phone2,
        contact_phone: data.follow_up_contact.contact_phone,
        contact_cell_phone: data.follow_up_contact.contact_cell_phone,
      });

      setSameFollowUpContactData(
        data.authorization.same_follow_up_contact_data
      );

      if (data.hasOwnProperty("participants") && data.participants.length > 0) {
        setParticipantValues(data.participants);
      }
    })
    .catch((err) => {
      Swal.fire({
        title: "Error",
        icon: "error",
      });
    });
};

const get_all_promoters = async (setPromoters, userData) => {
  await fetch(`${import.meta.env.VITE_API_URL}/user/promoters`, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-type": "application/json; charset=UTF-8",
      Authorization: `Bearer ${userData.access_token}`,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      setPromoters(data);
    })
    .catch((err) => {
      Swal.fire({
        title: "Error",
        icon: "error",
      });
    });
};

const filter = createFilterOptions();

const buildSelectOptions = (fieldName, optionsData) => {
  let options = [];

  switch (fieldName) {
    case "title":
      options = optionsData["titles"];
      break;
    case "origin":
      options = optionsData["origins"];
      break;
    case "origin_media":
      options = optionsData["origin_media"];
      break;
    case "interes":
      options = optionsData["interesOptions"];
      break;
    case "stage":
      options = optionsData["stageOptions"];
      break;
  }

  return options.map((option) => (
    <MenuItem
      key={option.id}
      value={option.value}
      disabled={option.label === DISCARDED && fieldName !== STAGE}
    >
      {option.label}
    </MenuItem>
  ));
};

const AddNewRegister = ({ userData, ownerEmail, setNewRegister }) => {
  const [open, setOpen] = useState(false);
  const [courses, setCourses] = useState([]);
  const [promoters, setPromoters] = useState([]);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [companyValue, setCompanyValue] = useState(null);
  const [newCompany, setNewCompany] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    get_all_courses(setCourses, userData);
    get_all_promoters(setPromoters, userData);
    get_all_companies();
  };

  const handleCloseNew = () => {
    setOpen(false);
    setCompanyValue(null);
  };

  const createNewRegister = async (bodyRequest) => {
    await fetch(`${import.meta.env.VITE_API_URL}/register/new_register`, {
      method: "POST",
      body: JSON.stringify(bodyRequest),
      credentials: "include",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${userData.access_token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.hasOwnProperty("success") && data.success) {
          handleCloseNew();
          setNewRegister((prevRegisters) => [
            ...prevRegisters,
            data.created_register,
          ]);
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Registro creado",
            showConfirmButton: false,
            timer: 3000,
          });
        } else {
          Swal.fire({
            title: "Error",
            icon: "error",
            text: data.detail,
            customClass: {
              container: "duplicate-main-email-alert",
            },
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          title: "Error",
          icon: "error",
        });
      });
  };

  const get_all_companies = async () => {
    await fetch(`${import.meta.env.VITE_API_URL}/company/get_companies`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${userData.access_token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setCompanies(data);
      })
      .catch((err) => {
        Swal.fire({
          title: "Error",
          icon: "error",
        });
      });
  };

  return (
    <>
      <Button
        onClick={handleClickOpen}
        variant="contained"
        className="add-new-register-button"
        sx={{
          borderRadius: 5,
        }}
      >
        Nuevo registro
      </Button>
      <Dialog
        open={open}
        scroll="paper"
        PaperProps={{
          component: "form",
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());

            let bodyRequest = {};

            bodyRequest["owner"] = ownerEmail;
            registerFields.forEach((field) => {
              bodyRequest[field.name] = formJson[field.name];
            });

            bodyRequest["related_courses"] = selectedCourses.map(
              (course) => course.simple_course_id
            );

            bodyRequest["company"] =
              companyValue !== null ? companyValue.title : "";

            if (companyValue !== null && companyValue.hasOwnProperty("id")) {
              bodyRequest["company_id"] = companyValue.id;
            }

            if (newCompany) {
              bodyRequest["new_company"] = true;
            }

            createNewRegister(bodyRequest);
          },
        }}
      >
        <DialogTitle>Nuevo registro</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Escriba los datos que sean requeridos.
          </DialogContentText>
          {registerFields.map((field) =>
            field.isSelect ? (
              field.name === "related_courses" ? (
                <Autocomplete
                  id="courses-new-client-register"
                  key={field.id}
                  name={field.name}
                  multiple
                  disablePortal
                  fullWidth
                  options={courses}
                  sx={{ marginTop: 1 }}
                  onChange={(event, newValue) => {
                    if (newValue !== null) {
                      setSelectedCourses(newValue);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required={field.required}
                      label="Curso"
                    />
                  )}
                />
              ) : (
                <TextField
                  key={field.id}
                  select
                  fullWidth
                  label={field.label}
                  defaultValue={field.name === "stage" ? "Nueva solicitud" : ""}
                  required={field.required}
                  margin="dense"
                  name={field.name}
                  variant="outlined"
                  color="secondary"
                >
                  {buildSelectOptions(field.name, {
                    titles: titleOptions,
                    origins: promoters,
                    origin_media: originMediaOptions,
                    interesOptions: interesOptionsData,
                    stageOptions: stageOptionsData,
                  })}
                </TextField>
              )
            ) : field.name === "company" ? (
              <Autocomplete
                key={field.id}
                variant="standard"
                value={companyValue}
                onChange={(event, newValue) => {
                  if (newValue && newValue.inputValue) {
                    setCompanyValue({
                      title: newValue.inputValue,
                    });
                    setNewCompany(true);
                  } else {
                    setCompanyValue(newValue); // id and title object
                    setNewCompany(false);
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);

                  const { inputValue } = params;
                  const isExisting = options.some(
                    (option) => inputValue === option.title
                  );
                  if (inputValue !== "" && !isExisting) {
                    filtered.push({
                      inputValue,
                      title: `Agregar "${inputValue}"`,
                    });
                  }

                  return filtered;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                id="company-new-client-register"
                options={companies}
                getOptionLabel={(option) => {
                  if (typeof option === "string") {
                    return option;
                  }
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                  return option.title;
                }}
                renderOption={(props, option) => {
                  const { key, ...optionProps } = props;
                  return (
                    <li key={key} {...optionProps}>
                      {option.title}
                    </li>
                  );
                }}
                freeSolo
                renderInput={(params) => (
                  <TextField
                    required={field.required}
                    margin="dense"
                    color="secondary"
                    {...params}
                    label="Empresa"
                  />
                )}
              />
            ) : field.type === "tel" ? (
              <PhoneNumber
                key={field.id}
                fieldLabel={field.label}
                fieldName={field.name}
              />
            ) : (
              <TextField
                key={field.id}
                fullWidth
                required={field.required}
                multiline={field.multiline}
                rows={field.multiline ? 4 : 1}
                margin="dense"
                label={field.label}
                name={field.name}
                type={field.type}
                variant="outlined"
                color="secondary"
              />
            )
          )}
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={handleCloseNew}>
            Cancelar
          </Button>
          <Button type="submit" color="secondary" variant="contained">
            Agregar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const ModifyRegister = ({
  userData,
  ownerEmail,
  registerData,
  setUpdatedRegister,
}) => {
  const [open, setOpen] = useState(false);
  const [courses, setCourses] = useState([]);
  const [promoters, setPromoters] = useState([]);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [companyValue, setCompanyValue] = useState({
    id: registerData.company_id,
    title: registerData.company,
  });
  const [newCompany, setNewCompany] = useState(false);

  let payload = decodeJWT(userData.access_token);

  const handleClickOpen = () => {
    get_all_courses(setCourses, userData);
    get_all_promoters(setPromoters, userData);
    get_all_companies();
    setOpen(true);
  };

  useEffect(() => {
    setSelectedCourses(
      courses.filter((obj) =>
        registerData.related_courses.includes(obj.simple_course_id)
      )
    );
  }, [courses]);

  const handleCloseEdition = () => {
    setOpen(false);
    setCompanyValue(null);
  };

  const get_all_companies = async () => {
    await fetch(`${import.meta.env.VITE_API_URL}/company/get_companies`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${userData.access_token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setCompanies(data);
      })
      .catch((err) => {
        Swal.fire({
          title: "Error",
          icon: "error",
        });
      });
  };

  const updateRegister = async (bodyRequest) => {
    await fetch(`${import.meta.env.VITE_API_URL}/register/update_register`, {
      method: "POST",
      body: JSON.stringify(bodyRequest),
      credentials: "include",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${userData.access_token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setUpdatedRegister((prevRegisters) =>
          prevRegisters.map((register) =>
            register.id === data.updated_register.id
              ? data.updated_register
              : register
          )
        );
        Swal.fire({
          title: "Registro modificado",
          icon: "success",
        });
      })
      .catch((err) => {
        Swal.fire({
          title: "Error",
          icon: "error",
        });
      });
  };

  const deleteClientInfo = async (registerId) => {
    await fetch(
      `${import.meta.env.VITE_API_URL}/register/delete_register/${registerId}`,
      {
        method: "DELETE",
        credentials: "include",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${userData.access_token}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setUpdatedRegister((prevRegisters) =>
            prevRegisters.filter((register) => register.id !== registerId)
          );

          Swal.fire({
            title: "Registro eliminado",
            icon: "success",
          });
        }

        if (data.hasOwnProperty("detail")) {
          Swal.fire({
            title: "Error",
            icon: "error",
            text: data.detail,
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          title: "Error",
          icon: "error",
        });
      });
  };

  const ConfirmRegistryDeletionDialog = () => {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    const confirmButton = () => {
      deleteClientInfo(registerData.id);
      handleClose();
      handleCloseEdition();
    };

    const cancelButton = () => {
      handleClose();
    };

    return (
      <Fragment>
        <Button
          variant="contained"
          color="error"
          onClick={handleClickOpen}
          sx={{
            marginRight: "auto",
          }}
        >
          Eliminar
        </Button>
        <Dialog open={open} aria-labelledby="responsive-dialog-title">
          <DialogTitle id="responsive-dialog-title" color="#d32f2f">
            ¿Seguro de eliminar este registro de cliente?
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <b>Esta acción no se puede deshacer ‼️</b>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="error" autoFocus onClick={cancelButton}>
              cancelar
            </Button>
            <Button
              color="error"
              variant="contained"
              onClick={confirmButton}
              autoFocus
            >
              Estoy seguro
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  };

  return (
    <>
      <IconButton
        onClick={handleClickOpen}
        variant="contained"
        color="warning"
        sx={{
          borderRadius: 2.5,
          padding: 0,
        }}
      >
        <EditNoteIcon />
      </IconButton>
      <Dialog
        open={open}
        scroll="paper"
        PaperProps={{
          component: "form",
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());

            let bodyRequest = {};

            bodyRequest["owner"] = ownerEmail;
            bodyRequest["register_id"] = registerData.id;
            registerFields.forEach((field) => {
              bodyRequest[field.name] = formJson[field.name];
            });

            bodyRequest["related_courses"] = selectedCourses.map(
              (course) => course.simple_course_id
            );

            if (companyValue !== null)
              bodyRequest["company"] = companyValue.title;

            if (companyValue !== null && companyValue.hasOwnProperty("id")) {
              bodyRequest["company_id"] = companyValue.id;
            }

            if (newCompany) {
              bodyRequest["new_company"] = true;
            }

            updateRegister(bodyRequest);

            handleCloseEdition();
          },
        }}
      >
        <DialogTitle>Modificar registro</DialogTitle>
        <DialogContent>
          <DialogContentText>Aqui puede editar los datos.</DialogContentText>
          {registerFields.map((field) =>
            field.isSelect ? (
              field.name === "related_courses" ? (
                <Autocomplete
                  id="courses-modify-client-register"
                  key={field.id}
                  name={field.name}
                  options={courses}
                  multiple
                  defaultValue={selectedCourses}
                  value={selectedCourses}
                  disablePortal
                  fullWidth
                  sx={{ marginTop: 1 }}
                  onChange={(event, newValue) => setSelectedCourses(newValue)}
                  renderInput={(params) => (
                    <TextField
                      required={field.required}
                      color="secondary"
                      {...params}
                      label="Cursos relacionados"
                    />
                  )}
                />
              ) : (
                promoters.length > 0 && (
                  <TextField
                    key={field.id}
                    select
                    fullWidth
                    label={field.label}
                    defaultValue={registerData[field.name]}
                    required={field.required}
                    margin="dense"
                    name={field.name}
                    variant="outlined"
                    color="secondary"
                  >
                    {buildSelectOptions(field.name, {
                      titles: titleOptions,
                      origins: promoters,
                      origin_media: originMediaOptions,
                      interesOptions: interesOptionsData,
                      stageOptions: stageOptionsData,
                    })}
                  </TextField>
                )
              )
            ) : field.name === "company" ? (
              <Autocomplete
                key={field.id}
                variant="standard"
                value={
                  companyValue !== null
                    ? companyValue
                    : registerData[field.name]
                }
                onChange={(event, newValue) => {
                  if (newValue && newValue.inputValue) {
                    setCompanyValue({
                      title: newValue.inputValue,
                    });
                    // adding new company
                    setNewCompany(true);
                  } else {
                    // selecting existing company
                    setCompanyValue(newValue);
                    setNewCompany(false);
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);

                  const { inputValue } = params;
                  const isExisting = options.some(
                    (option) => inputValue === option.title
                  );
                  if (inputValue !== "" && !isExisting) {
                    filtered.push({
                      inputValue,
                      title: `Agregar "${inputValue}"`,
                    });
                  }

                  return filtered;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                id="company-new-client-register"
                options={companies}
                getOptionLabel={(option) => {
                  if (typeof option === "string") {
                    return option;
                  }
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                  return option.title;
                }}
                renderOption={(props, option) => {
                  const { key, ...optionProps } = props;
                  return (
                    <li key={key} {...optionProps}>
                      {option.title}
                    </li>
                  );
                }}
                freeSolo
                renderInput={(params) => (
                  <TextField
                    required={field.required}
                    margin="dense"
                    color="secondary"
                    {...params}
                    label="Empresa"
                  />
                )}
              />
            ) : field.type === "tel" ? (
              <PhoneNumber
                key={field.id}
                fieldLabel={field.label}
                fieldName={field.name}
                savedValue={registerData[field.name]}
              />
            ) : (
              <TextField
                key={field.id}
                fullWidth
                required={field.required}
                multiline={field.multiline}
                rows={field.multiline ? 4 : 1}
                margin="dense"
                label={field.label}
                defaultValue={registerData[field.name]}
                name={field.name}
                type={field.type}
                variant="outlined"
                color="secondary"
              />
            )
          )}
        </DialogContent>
        <DialogActions>
          {payload.role == ADMIN && <ConfirmRegistryDeletionDialog />}
          <Button color="warning" onClick={handleCloseEdition}>
            Cancelar
          </Button>
          <Button type="submit" color="warning" variant="contained">
            Modificar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const Selector = ({ field, savedValue }) => {
  const [selectedValue, setSelectedValue] = useState(savedValue || "");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  useEffect(() => {
    if (savedValue) {
      setSelectedValue(savedValue);
    } else setSelectedValue("");
  }, [savedValue]);

  return (
    <TextField
      size="small"
      select
      fullWidth
      label={field.label}
      value={selectedValue}
      required={field.required}
      margin="dense"
      name={field.name}
      variant="outlined"
      color="secondary"
      onChange={handleChange}
    >
      {field.options.map((option) => (
        <MenuItem key={option.id} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

const DateComponent = ({ field, savedValue }) => {
  const [selectedDate, setSelectedDate] = useState(savedValue || "");

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  useEffect(() => {
    if (savedValue) {
      setSelectedDate(savedValue);
    }
  }, [savedValue]);

  return (
    <TextField
      size="small"
      fullWidth
      required={field.required}
      margin="dense"
      label={field.label}
      name={field.name}
      type={field.type}
      InputLabelProps={{
        shrink: true,
      }}
      variant="outlined"
      color="secondary"
      value={selectedDate} // El valor del estado se refleja en el campo
      onChange={handleDateChange} // Captura el valor del input de tipo date
    />
  );
};

const InputComponent = ({
  field,
  savedValue,
  setParticipantValues,
  participantIndex,
}) => {
  const [inputValue, setInputValue] = useState(savedValue);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputValue(value);

    if (participantIndex === undefined) {
      return;
    }

    setParticipantValues((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues[participantIndex] = {
        ...updatedValues[participantIndex],
        [name]: value,
      };
      return updatedValues;
    });
  };

  useEffect(() => {
    if (savedValue) {
      setInputValue(savedValue);
    }
  }, [savedValue]);

  return (
    <TextField
      size="small"
      fullWidth
      required={field.required}
      margin="dense"
      label={field.label}
      name={field.name}
      type={field.type}
      variant="outlined"
      color="secondary"
      value={inputValue}
      onChange={handleInputChange}
    />
  );
};

const PARTICIPANT = {
  full_name: "",
  position: "",
  email: "",
  headquarters: "",
  seminar: "",
};

const ConvertToClient = ({
  registerId,
  companyId,
  company,
  ownerEmail,
  clientName,
  userData,
  setUpdatedRegister,
}) => {
  const { item: followUpElements, saveItem: addFollowUpElement } =
    useContext(AppContext);
  const [open, setOpen] = useState(false);
  const [courses, setCourses] = useState([]);
  const [companyData, setCompanyData] = useState({});
  const [selectedCourse, setSelectedCourse] = useState([]);
  const [participants, setParticipants] = useState(participantFields);
  const [participantValues, setParticipantValues] = useState([]);
  const [phoneData, setPhoneData] = useState({
    phone1: "",
    phone2: "",
    contact_phone: "",
    contact_cellphone: "",
  });
  const [sameFollowUpContactData, setSameFollowUpContactData] = useState(true);

  const handleClickOpen = () => {
    setOpen(true);
    get_company_data(
      companyId,
      setCompanyData,
      setParticipantValues,
      setPhoneData,
      setSameFollowUpContactData,
      userData
    );
    get_all_courses(setCourses, userData);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const updateRegisterStatus = async (bodyRequest) => {
    await fetch(`${import.meta.env.VITE_API_URL}/register/update_status`, {
      method: "POST",
      body: JSON.stringify(bodyRequest),
      credentials: "include",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${userData.access_token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setUpdatedRegister((prevRegisters) =>
          prevRegisters.map((register) =>
            register.id === data.updated_register.id
              ? data.updated_register
              : register
          )
        );
        Swal.fire({
          title: "Estatus actualizado",
          text: `El usuario ${clientName} ha sido convertido a cliente`,
          icon: "success",
        });
      })
      .catch((err) => {
        Swal.fire({
          title: "Error",
          icon: "error",
        });
      });
  };

  const getFollowUpData = async (registerId) => {
    await fetch(
      `${import.meta.env.VITE_API_URL}/register/get_follow_up/${registerId}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${userData.access_token}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        addFollowUpElement(data);
      })
      .catch((err) => {
        Swal.fire({
          title: "Error",
          icon: "error",
        });
      });
  };

  const previousElements = (followUpElements, updatedRegister) => {
    const updatedRegisterId = updatedRegister["register_id"];
    if (
      followUpElements.length > 0 &&
      followUpElements.at(0)["register_id"] == updatedRegisterId
    ) {
      addFollowUpElement([...followUpElements, updatedRegister]);
    }

    getFollowUpData(updatedRegisterId);
  };

  const updateCompany = async (bodyRequest) => {
    await fetch(`${import.meta.env.VITE_API_URL}/company/update`, {
      method: "POST",
      body: JSON.stringify(bodyRequest),
      credentials: "include",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${userData.access_token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (
          data.hasOwnProperty("follow_up") &&
          data.follow_up.hasOwnProperty("updated_register")
        ) {
          previousElements(followUpElements, data.follow_up.updated_register);
        }
      })
      .catch((err) => {
        Swal.fire({
          title: "Error",
          icon: "error",
        });
      });
  };

  const isInvalidNumber = (coursesQuantity) => {
    if (
      coursesQuantity == null ||
      coursesQuantity == undefined ||
      coursesQuantity == "" ||
      coursesQuantity <= 0
    ) {
      return true;
    }

    return false;
  };

  const handleAddParticipant = () => {
    if (
      companyData.hasOwnProperty("participants") &&
      companyData.participants.length == 0
    ) {
      setParticipants((prevParticipants) => [
        ...prevParticipants,
        ...participantFields,
      ]);
    } else {
      setCompanyData((prevDataCompany) => ({
        ...prevDataCompany,
        participants: [...prevDataCompany.participants, PARTICIPANT],
      }));
    }
  };

  const handleRemoveParticipant = () => {
    if (
      companyData.hasOwnProperty("participants") &&
      companyData.participants.length == 0
    ) {
      setParticipants((prevParticipants) => prevParticipants.slice(0, -5));
    } else {
      setCompanyData((prevDataCompany) => ({
        ...prevDataCompany,
        participants: prevDataCompany.participants.slice(0, -1),
      }));
      setParticipantValues((prevValues) => prevValues.slice(0, -1));
    }
  };

  const disableRemoveButton = (companyData, participants) => {
    if (
      companyData.hasOwnProperty("participants") &&
      companyData.participants.length == 0
    ) {
      return participants.length <= 5;
    } else {
      return (
        companyData.hasOwnProperty("participants") &&
        companyData.participants.length <= 1
      );
    }
  };

  const AuthorizationCheckbox = () => {
    const handleChange = (event) => {
      setSameFollowUpContactData(event.target.checked);
    };

    return (
      <Checkbox checked={sameFollowUpContactData} onChange={handleChange} />
    );
  };

  return (
    <>
      <IconButton
        id={registerId}
        onClick={handleClickOpen}
        variant="contained"
        color="secondary"
        sx={{
          borderRadius: 2.5,
          padding: 0,
        }}
      >
        <SwitchAccessShortcutIcon />
      </IconButton>
      <Dialog
        open={open}
        PaperProps={{
          component: "form",
          className: "dialog-company-form",
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());

            let coursesQuantity = formJson["courses_quantity"];
            let statusBody = {};

            if (isInvalidNumber(coursesQuantity)) {
              Swal.fire({
                title: "Error",
                text: "Debe ingresar la cantidad de cursos adquiridos",
                icon: "error",
              });
              return;
            }

            statusBody["register_id"] = registerId;
            statusBody["owner"] = ownerEmail;
            statusBody["company"] = company;
            statusBody["course_acquired"] = selectedCourse.simple_course_id;
            statusBody["courses_quantity"] = coursesQuantity;

            updateRegisterStatus(statusBody);

            let companyBody = {
              billing_details: {},
              follow_up_contact: {},
              authorization: {},
              participants: [],
            };

            billingFields.forEach((field) => {
              companyBody["billing_details"][field.name] =
                field.type === "tel"
                  ? phoneData[field.name]
                  : formJson[field.name];
            });

            followUpFields.forEach((field) => {
              companyBody["follow_up_contact"][field.name] =
                field.type === "tel"
                  ? phoneData[field.name]
                  : formJson[field.name];
            });

            companyBody["authorization"]["same_follow_up_contact_data"] =
              sameFollowUpContactData;

            if (sameFollowUpContactData) {
              const equivalentNames = {
                authorization_full_name: "contact_name",
                authorization_position: "contact_position",
                authorization_email: "contact_email",
              };

              let followUpContact = companyBody.follow_up_contact;
              authorizationFields.forEach((field) => {
                companyBody["authorization"][field.name] =
                  followUpContact[equivalentNames[field.name]];
              });
            } else {
              authorizationFields.forEach((field) => {
                companyBody["authorization"][field.name] =
                  field.type === "tel"
                    ? phoneData[field.name]
                    : formJson[field.name];
              });
            }

            companyBody["company_id"] = companyId;
            companyBody["register_id"] = registerId;
            companyBody["owner"] = ownerEmail;
            companyBody["is_purchase"] = true;
            companyBody["participants"] = participantValues;

            updateCompany(companyBody);

            handleClose();
          },
        }}
      >
        <DialogTitle>Convertir a cliente</DialogTitle>
        <DialogContent className="DialogContent-container">
          <DialogContentText mb={1}>
            <b>DATOS DE FACTURACIÓN</b>
          </DialogContentText>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              {billingFields.map((field, index) =>
                field.isSelect ? (
                  <Grid key={index} item xs={field.gridSize}>
                    <Selector
                      field={field}
                      savedValue={
                        companyData.hasOwnProperty("billing_details") &&
                        companyData.billing_details[field.name]
                          ? companyData.billing_details[field.name]
                          : false
                      }
                    />
                  </Grid>
                ) : field.type === "tel" ? (
                  <Grid key={index} item xs={field.gridSize}>
                    <PhoneNumber
                      initialState={phoneData}
                      savedValue={
                        companyData.hasOwnProperty("billing_details") &&
                        companyData.billing_details[field.name]
                          ? companyData.billing_details[field.name]
                          : false
                      }
                      setValueWrapper={setPhoneData}
                      required={field.required}
                      fieldLabel={field.label}
                      fieldName={field.name}
                      size="small"
                    />
                  </Grid>
                ) : field.type === "date" ? (
                  <Grid key={index} item xs={field.gridSize}>
                    <DateComponent
                      field={field}
                      savedValue={
                        companyData.hasOwnProperty("billing_details") &&
                        companyData.billing_details[field.name]
                          ? companyData.billing_details[field.name]
                          : false
                      }
                    />
                  </Grid>
                ) : (
                  <Grid key={index} item xs={field.gridSize}>
                    <InputComponent
                      field={field}
                      savedValue={
                        companyData.hasOwnProperty("billing_details") &&
                        companyData.billing_details[field.name]
                          ? companyData.billing_details[field.name]
                          : ""
                      }
                    />
                  </Grid>
                )
              )}
            </Grid>
          </Box>

          <DialogContentText mt={2} mb={1}>
            <b>CONTACTO DE SEGUIMIENTO</b>
          </DialogContentText>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              {followUpFields.map((field, index) =>
                field.isSelect ? (
                  <Grid key={index} item xs={field.gridSize}>
                    <Selector
                      field={field}
                      savedValue={
                        companyData.hasOwnProperty("follow_up_contact") &&
                        companyData.follow_up_contact[field.name]
                          ? companyData.follow_up_contact[field.name]
                          : false
                      }
                    />
                  </Grid>
                ) : field.type === "tel" ? (
                  <Grid key={index} item xs={field.gridSize}>
                    <PhoneNumber
                      initialState={phoneData}
                      savedValue={
                        companyData.hasOwnProperty("follow_up_contact") &&
                        companyData.follow_up_contact[field.name]
                          ? companyData.follow_up_contact[field.name]
                          : false
                      }
                      setValueWrapper={setPhoneData}
                      fieldLabel={field.label}
                      fieldName={field.name}
                      size="small"
                      required={field.required}
                    />
                  </Grid>
                ) : (
                  <Grid key={index} item xs={field.gridSize}>
                    <InputComponent
                      field={field}
                      savedValue={
                        companyData.hasOwnProperty("follow_up_contact") &&
                        companyData.follow_up_contact[field.name]
                          ? companyData.follow_up_contact[field.name]
                          : ""
                      }
                    />
                  </Grid>
                )
              )}
            </Grid>
          </Box>

          <DialogContentText mt={2} mb={1}>
            <b>AUTORIZACIÓN</b>
          </DialogContentText>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<AuthorizationCheckbox />}
                  label="Utilizar datos del contacto de seguimiento"
                />
              </Grid>
              {!sameFollowUpContactData &&
                authorizationFields.map((field, index) =>
                  field.isSelect ? (
                    <Grid key={index} item xs={field.gridSize}>
                      <Selector
                        field={field}
                        savedValue={
                          companyData.hasOwnProperty("authorization") &&
                          companyData.authorization[field.name]
                            ? companyData.authorization[field.name]
                            : false
                        }
                      />
                    </Grid>
                  ) : field.type === "tel" ? (
                    <Grid key={index} item xs={field.gridSize}>
                      <PhoneNumber
                        initialState={phoneData}
                        savedValue={
                          companyData.hasOwnProperty("authorization") &&
                          companyData.authorization[field.name]
                            ? companyData.authorization[field.name]
                            : false
                        }
                        setValueWrapper={setPhoneData}
                        fieldLabel={field.label}
                        fieldName={field.name}
                        size="small"
                        required={field.required}
                      />
                    </Grid>
                  ) : (
                    <Grid key={index} item xs={field.gridSize}>
                      <InputComponent
                        field={field}
                        savedValue={
                          companyData.hasOwnProperty("authorization") &&
                          companyData.authorization[field.name]
                            ? companyData.authorization[field.name]
                            : ""
                        }
                      />
                    </Grid>
                  )
                )}
            </Grid>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: 2,
              mt: 2,
            }}
          >
            <DialogContentText>
              <b>PARTICIPANTES</b>
            </DialogContentText>
            <IconButton aria-label="add" onClick={handleAddParticipant}>
              <AddIcon />
            </IconButton>
            <IconButton
              disabled={disableRemoveButton(companyData, participants)}
              aria-label="remove"
              onClick={handleRemoveParticipant}
            >
              <RemoveIcon />
            </IconButton>
          </Box>

          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2} columns={20}>
              {companyData.hasOwnProperty("participants") &&
              companyData.participants.length > 0
                ? companyData.participants.map(
                    (participant, participantIndex) => (
                      <Fragment key={participantIndex}>
                        {participants.map((field, index) =>
                          field.isSelect ? (
                            <Grid key={index} item xs={field.gridSize}>
                              <Selector
                                field={field}
                                savedValue={participant[field.name]}
                              />
                            </Grid>
                          ) : field.type === "tel" ? (
                            <Grid key={index} item xs={field.gridSize}>
                              <PhoneNumber
                                initialState={phoneData}
                                savedValue={participant[field.name]}
                                setValueWrapper={setPhoneData}
                                fieldLabel={field.label}
                                fieldName={field.name}
                                size="small"
                                required={field.required}
                              />
                            </Grid>
                          ) : (
                            <Grid key={index} item xs={field.gridSize}>
                              <InputComponent
                                field={field}
                                savedValue={participant[field.name]}
                                setParticipantValues={setParticipantValues}
                                participantIndex={participantIndex}
                              />
                            </Grid>
                          )
                        )}
                      </Fragment>
                    )
                  )
                : participants.map((field, index) =>
                    field.isSelect ? (
                      <Grid key={index} item xs={field.gridSize}>
                        <TextField
                          size="small"
                          select
                          fullWidth
                          label={field.label}
                          defaultValue=""
                          required={field.required}
                          margin="dense"
                          name={field.name}
                          variant="outlined"
                          color="secondary"
                        >
                          {field.options.map((option) => (
                            <MenuItem key={option.id} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    ) : field.type === "tel" ? (
                      <Grid key={index} item xs={field.gridSize}>
                        <PhoneNumber
                          fieldLabel={field.label}
                          fieldName={field.name}
                          size="small"
                        />
                      </Grid>
                    ) : (
                      <Grid key={index} item xs={field.gridSize}>
                        <InputComponent
                          field={field}
                          savedValue={""}
                          setParticipantValues={setParticipantValues}
                          participantIndex={Math.floor(index / 5)}
                        />
                      </Grid>
                    )
                  )}
            </Grid>
          </Box>

          <DialogContentText mt={2}>
            ¿Convertir al usuario <b>{clientName}</b> en cliente del curso
            seleccionado a continuación?
          </DialogContentText>
          <Autocomplete
            id="combo-box-courses"
            fullWidth
            disablePortal
            options={courses}
            sx={{ marginTop: 2 }}
            onChange={(event, newValue) => setSelectedCourse(newValue)}
            renderInput={(params) => (
              <TextField required color="secondary" {...params} label="Curso" />
            )}
          />
          <DialogContentText sx={{ mt: 3, mb: 1 }}>
            ¿Cuántos pax ha adquirido el cliente?
          </DialogContentText>
          <TextField
            id="courses_quantity"
            name="courses_quantity"
            color="secondary"
            label="Cantidad de cursos"
            defaultValue={1}
            variant="outlined"
            inputProps={{ min: 1 }}
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button type="submit" color="secondary" variant="contained">
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const Home = () => {
  const { userData } = useContext(AppContext);
  const [registers, setRegisters] = useState([]);
  const [disableRestart, setDisableRestart] = useState(true);

  const get_all_registers = async () => {
    await fetch(`${import.meta.env.VITE_API_URL}/register/get_registers`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${userData.access_token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setRegisters(data);
      })
      .catch((err) => {
        Swal.fire({
          title: "Error",
          icon: "error",
        });
      });
  };

  const search_register_info = async () => {
    let searchText = document.getElementById("query-term-to-search").value;

    if (searchText == null || searchText == undefined || searchText == "") {
      return;
    }

    await fetch(
      `${import.meta.env.VITE_API_URL}/register/find_registers/${searchText}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${userData.access_token}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setRegisters(data);

        if (data.length >= 0) {
          setDisableRestart(false);
        }
      })
      .catch((err) => {
        Swal.fire({
          title: "Error",
          icon: "error",
        });
      });
  };

  const restart_data = () => {
    document.getElementById("query-term-to-search").value = "";
    setDisableRestart(true);
    get_all_registers();
  };

  useEffect(() => {
    get_all_registers();
  }, []);

  let payload;

  if (userData.hasOwnProperty("access_token")) {
    payload = decodeJWT(userData.access_token);
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      search_register_info();
    }
  };

  return (
    <>
      <Navbar />
      <Container maxWidth="xl" className="container-more-than-full-width">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={6} className="search-input-container">
              <StyledPaper>
                <TextField
                  onKeyDown={handleKeyDown}
                  id="query-term-to-search"
                  className="input-query-search"
                  label="Texto de consulta"
                  variant="outlined"
                  color="secondary"
                  fullWidth
                  size="small"
                  placeholder="Escriba su búsqueda"
                />
              </StyledPaper>
            </Grid>
            <Grid item xs={6} className="buttons-container">
              <StyledPaper>
                <Stack direction="row" spacing={2}>
                  <Button
                    className="complementary-buttons-search"
                    onClick={search_register_info}
                    variant="contained"
                    color="secondary"
                  >
                    Buscar
                  </Button>
                  <Button
                    className="complementary-buttons-search"
                    onClick={restart_data}
                    disabled={disableRestart}
                    variant="contained"
                    color="info"
                  >
                    Restablecer
                  </Button>
                  <AddNewRegister
                    userData={userData}
                    ownerEmail={payload.email}
                    setNewRegister={setRegisters}
                  />
                </Stack>
              </StyledPaper>
            </Grid>
            <Grid item xs={12} className="main-table-container">
              <StyledPaper className="styled-paper-table-container">
                <EnhancedTable
                  registers={registers}
                  headCells={headCells}
                  ModifyButton={ModifyRegister}
                  userData={userData}
                  ownerEmail={payload.email}
                  ConvertionButton={ConvertToClient}
                  setUpdatedRegister={setRegisters}
                />
              </StyledPaper>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export { Home };
