import { useState, useEffect, createContext } from "react";
import { useNavigate } from "react-router-dom";
import { useSessionStorage } from "./useSessionStorage";
import Swal from "sweetalert2";

const AppContext = createContext();

const AppProvider = ({ children }) => {
  const {
    item: userData,
    saveItem: saveDataUser,
    deleteItem: deleteDataUser,
  } = useSessionStorage("authData", {});
  const { item, saveItem } = useSessionStorage("appData", {});
  const navigate = useNavigate();

  const refreshToken = async (refreshToken, saveDataUser, logout) => {
    await fetch(`${import.meta.env.VITE_API_URL}/user/refresh_token`, {
      method: "POST",
      body: JSON.stringify({
        refresh_token: refreshToken,
      }),
      credentials: "include",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (!data) {
          return;
        }

        if (
          data.hasOwnProperty("access_token") &&
          data.hasOwnProperty("refresh_token")
        ) {
          saveDataUser(data);
        }

        if (data.hasOwnProperty("error") || data.hasOwnProperty("detail")) {
          logout();
          Swal.fire({
            title: "Sesión finalizada por inactividad",
            text: "Ha expirado la sesión, inicie sesión nuevamente",
            icon: "error",
          });
        }
      })
      .catch((err) => {
        Swal.fire({
          title: "Error",
          icon: "error",
        });
      });
  };

  const logout = () => {
    localStorage.setItem("loggedOut", Date.now());
    deleteDataUser();
    navigate("/");
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (!userData.access_token || !userData.refresh_token) {
        return;
      }

      refreshToken(userData.refresh_token, saveDataUser, logout);
    }, 15 * 60 * 1000);

    return () => clearInterval(interval);
  }, [userData]);

  return (
    <AppContext.Provider
      value={{
        userData,
        saveDataUser,
        deleteDataUser,
        item,
        saveItem,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };
