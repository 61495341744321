import { Fragment, useEffect, useState } from "react";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Popper from "@mui/material/Popper";

import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";

import dayjs from "dayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

const durations = [
  "",
  "1 día",
  "2 días",
  "3 días",
  "4 días",
  "5 días",
  "6 días",
  "7 días",
];

let durationOptions = durations.map((duration, index) => ({
  id: index,
  value: duration,
  label: duration,
}));

const modalities = [
  "",
  "Online",
  "Presencial",
  "Híbrido",
  "In Company",
  "Póliza",
];

let modalityOptions = modalities.map((modality, index) => ({
  id: index,
  value: modality,
  label: modality,
}));

const buildSelectOptions = (fieldName, optionsData) => {
  let options = [];

  switch (fieldName) {
    case "course_duration":
      options = optionsData["duration_options"];
      break;
    case "modality":
      options = optionsData["modality_options"];
      break;
    case "responsible_seller":
      options = optionsData["seller_options"];
      break;
  }

  return options.map((option) => (
    <MenuItem key={option.id} value={option.value}>
      {option.label}
    </MenuItem>
  ));
};

const SelectorComponent = ({ field, options, defaultValue }) => {
  const [value, setValue] = useState(defaultValue);

  return (
    <TextField
      select
      id={field.name}
      key={field.name}
      name={field.name}
      variant="outlined"
      color="secondary"
      fullWidth
      value={value}
      onChange={(event) => setValue(event.target.value)}
      label={field.placeholder}
      required={field.required}
      margin="dense"
    >
      {buildSelectOptions(field.name, options)}
    </TextField>
  );
};

const CourseDatePicker = ({ field, defaultValue, setCourseDate }) => {
  const [value, setValue] = useState(dayjs(defaultValue));

  const updateValues = (newValue) => {
    setValue(newValue);
    setCourseDate(newValue);
  };

  useEffect(() => {
    setCourseDate(defaultValue);
  }, [defaultValue]);

  return (
    <DesktopDatePicker
      value={value}
      onChange={(newValue) => updateValues(newValue)}
      format="DD/MM/YYYY"
      label={field.label}
      views={["year", "month", "day"]}
      slots={{
        textField: (props) => (
          <TextField fullWidth color="secondary" {...props} />
        ),
        popper: (props) => (
          <Popper {...props} disablePortal={true} sx={{ zIndex: 3 }} />
        ),
      }}
      sx={{ mt: 1 }}
    />
  );
};

const EarlyDatePicker = ({ field, defaultValue, setEarlyPaymentDate }) => {
  const [value, setValue] = useState(dayjs(defaultValue));

  const updateValues = (newValue) => {
    setValue(newValue);
    setEarlyPaymentDate(newValue);
  };

  useEffect(() => {
    setEarlyPaymentDate(defaultValue);
  }, [defaultValue]);

  return (
    <DesktopDatePicker
      value={value}
      onChange={(newValue) => updateValues(newValue)}
      format="DD/MM/YYYY"
      label={field.label}
      views={["year", "month", "day"]}
      slots={{
        textField: (props) => (
          <TextField fullWidth color="secondary" {...props} />
        ),
        popper: (props) => (
          <Popper {...props} disablePortal={true} sx={{ zIndex: 3 }} />
        ),
      }}
      sx={{ mt: 1 }}
    />
  );
};

const ButtonWithForm = ({
  mainButton,
  formFields,
  courseData,
  userEmail,
  translation,
  submitFunction,
  sellerUsers,
  formTitle,
  formContent,
  confirmButton,
}) => {
  const [open, setOpen] = useState(false);
  const [courseDate, setCourseDate] = useState(courseData.course_date);
  const [earlyPaymentDate, setEarlyPaymentDate] = useState(courseData.early_payment_date);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const validFields = [
    "course_name",
    "course_date",
    "course_duration",
    "early_payment_date",
    "modality",
    "responsible_seller",
    "updated_at",
    "updated_by",
  ];

  return (
    <Fragment>
      <IconButton aria-label="edit" onClick={handleClickOpen}>
        {mainButton}
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());

            let bodyRequest = {};

            formFields.forEach((field) => {
              if (validFields.includes(field.name)) {
                bodyRequest[field.name] = formJson[field.name];
              }
            });

            bodyRequest["course_id"] = courseData.id;
            bodyRequest["user"] = userEmail;
            bodyRequest["course_date"] = courseDate;
            bodyRequest["early_payment_date"] = earlyPaymentDate;

            submitFunction(bodyRequest);
            handleClose();
          },
        }}
      >
        <DialogTitle color={"secondary"}>{formTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText marginBottom={2}>{formContent}</DialogContentText>
          {Object.entries(courseData).map(([fieldName, defaultValue]) => {
            let field = formFields.find((field) => field.name === fieldName);

            if (field) {
              if (field.isSelect) {
                return (
                  <SelectorComponent
                    key={fieldName}
                    field={field}
                    options={{
                      duration_options: durationOptions,
                      modality_options: modalityOptions,
                      seller_options: sellerUsers,
                    }}
                    defaultValue={defaultValue}
                  />
                );
              }

              if (field.isDate && fieldName === "course_date") {
                return (
                  <CourseDatePicker
                    key={fieldName}
                    field={field}
                    defaultValue={defaultValue}
                    setCourseDate={setCourseDate}
                  />
                );
              }

              if (field.isDate && fieldName === "early_payment_date") {
                return (
                  <EarlyDatePicker
                    key={fieldName}
                    field={field}
                    defaultValue={defaultValue}
                    setEarlyPaymentDate={setEarlyPaymentDate}
                  />
                );
              }
            }

            if (
              fieldName === "course_name" ||
              fieldName === "updated_at" ||
              fieldName === "updated_by"
            ) {
              return (
                <TextField
                  key={fieldName}
                  fullWidth
                  color="secondary"
                  margin="dense"
                  variant="outlined"
                  name={fieldName}
                  label={translation(fieldName)}
                  defaultValue={courseData[fieldName]}
                  type="text"
                  disabled={
                    fieldName === "updated_at" || fieldName === "updated_by"
                      ? true
                      : false
                  }
                />
              );
            }
          })}
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button color="secondary" variant="contained" type="submit">
            {confirmButton}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export { ButtonWithForm };
