import { useState, useEffect } from "react";
import Swal from "sweetalert2";
import dayjs from "dayjs";

import { ButtonWithForm } from "./ButtonWithForm";
import { DeleteDialog } from "./DeleteForm";

import random1 from "../../assets/tech_images/random1.jpeg";
import random2 from "../../assets/tech_images/random2.jpeg";
import random3 from "../../assets/tech_images/random3.jpeg";
import random4 from "../../assets/tech_images/random4.jpeg";
import random5 from "../../assets/tech_images/random5.jpeg";
import random6 from "../../assets/tech_images/random6.jpeg";
import random7 from "../../assets/tech_images/random7.jpeg";
import random8 from "../../assets/tech_images/random8.jpeg";
import random9 from "../../assets/tech_images/random9.jpeg";
import random10 from "../../assets/tech_images/random10.jpeg";
import random11 from "../../assets/tech_images/random11.jpeg";
import random12 from "../../assets/tech_images/random12.jpeg";
import random13 from "../../assets/tech_images/random13.jpeg";
import random14 from "../../assets/tech_images/random14.jpeg";
import random15 from "../../assets/tech_images/random15.jpeg";
import random16 from "../../assets/tech_images/random16.jpeg";
import random17 from "../../assets/tech_images/random17.jpeg";
import random18 from "../../assets/tech_images/random18.jpeg";
import random19 from "../../assets/tech_images/random19.jpeg";
import random20 from "../../assets/tech_images/random20.jpeg";
import random21 from "../../assets/tech_images/random21.jpeg";
import random22 from "../../assets/tech_images/random22.jpeg";
import random23 from "../../assets/tech_images/random23.jpeg";
import random24 from "../../assets/tech_images/random24.jpeg";
import random25 from "../../assets/tech_images/random25.jpeg";

import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import EditNoteIcon from "@mui/icons-material/EditNote";
import DeleteIcon from "@mui/icons-material/Delete";

let images = [
  random1,
  random2,
  random3,
  random4,
  random5,
  random6,
  random7,
  random8,
  random9,
  random10,
  random11,
  random12,
  random13,
  random14,
  random15,
  random16,
  random17,
  random18,
  random19,
  random20,
  random21,
  random22,
  random23,
  random24,
  random25,
];

const getRandomImage = () => {
  const randomIndex = Math.floor(Math.random() * images.length);
  return images[randomIndex];
};

const CourseCard = ({
  data,
  userData,
  userEmail,
  courses,
  setCourses,
  formData,
  sellerUsers,
}) => {
  const [randomImage, setRandomImage] = useState("");

  const theme = useTheme();

  const getCourseData = (courseId) => {
    let response = courses.find((course) => course.id === courseId);
    return response;
  };

  const updateCourse = async (bodyRequest) => {
    await fetch(`${import.meta.env.VITE_API_URL}/course/update_course`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${userData.access_token}`,
      },
      body: JSON.stringify(bodyRequest),
    })
      .then((response) => response.json())
      .then((data) => {
        setCourses((prevCourses) =>
          prevCourses.map((course) =>
            course.id === data.updated_course.id ? data.updated_course : course
          )
        );

        Swal.fire({
          title: "Curso actualizado",
          icon: "success",
        });
      })
      .catch((err) => {
        Swal.fire({
          title: "Error",
          icon: "error",
        });
      });
  };

  const deleteCourse = async (courseId) => {
    await fetch(
      `${import.meta.env.VITE_API_URL}/course/delete_course/${courseId}`,
      {
        method: "DELETE",
        credentials: "include",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${userData.access_token}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setCourses((prevCourses) =>
          prevCourses.filter((course) => course.id !== courseId)
        );
        Swal.fire({
          title: "Curso eliminado",
          icon: "success",
        });
      })
      .catch((err) => {
        Swal.fire({
          title: "Error",
          icon: "error",
        });
      });
  };

  const translateText = (text) => {
    switch (text) {
      case "course_name":
        return "Nombre del curso";
      case "course_description":
        return "Descripción del curso";
      case "course_duration":
        return "Duración del curso";
      case "course_instructor":
        return "Instructor del curso";
      case "updated_at":
        return "Fecha de actualización";
      case "updated_by":
        return "Actualizado por";
      default:
        return text;
    }
  };

  useEffect(() => {
    let image = getRandomImage();
    setRandomImage(image);
  }, []);

  return (
    <Card sx={{ display: "flex", borderRadius: 5 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#e8c2a1",
        }}
      >
        <CardContent sx={{ flex: "1 0 auto" }}>
          <Typography component="div" variant="h6" fontWeight={700}>
            {data.course_name}
          </Typography>
          <Typography
            variant="subtitle1"
            color="text.secondary"
            component="div"
          >
            <b>Inicio: </b>
            {dayjs(data.course_date).format("DD/MM/YYYY")}
          </Typography>
          <Typography
            variant="subtitle1"
            color="text.secondary"
            component="div"
          >
            <b>Duración: </b>
            {data.course_duration}
          </Typography>
          {/* <Typography
            variant="subtitle1"
            color="text.secondary"
            component="div"
          >
            <b>Pronto pago: </b>
            {dayjs(data.course_date).format("DD/MM/YYYY")}
          </Typography>
          <Typography
            variant="subtitle1"
            color="text.secondary"
            component="div"
          >
            <b>Modalidad: </b>
            {data.modality}
          </Typography>
          <Typography
            variant="subtitle1"
            color="text.secondary"
            component="div"
          >
            <b>Responsable: </b>
            {data.responsible_seller}
          </Typography> */}
        </CardContent>
        <Box sx={{ display: "flex", alignItems: "center", pl: 1, pb: 1 }}>
          <ButtonWithForm
            mainButton={<EditNoteIcon sx={{ height: 38, width: 38 }} />}
            formFields={formData}
            courseData={getCourseData(data.id)}
            userEmail={userEmail}
            translation={translateText}
            submitFunction={updateCourse}
            sellerUsers={sellerUsers}
            formTitle={"Editar Curso"}
            formContent={"Puedes modificar los datos del curso seleccionado"}
            confirmButton={"Guardar Cambios"}
          />

          <DeleteDialog
            mainButton={
              <DeleteIcon sx={{ height: 38, width: 38, color: "#f45757" }} />
            }
            formTitle={"Eliminar Curso"}
            formContent={
              <span>
                ¿Estás seguro de que deseas eliminar el curso{" "}
                <b>{data.course_name}</b>?
              </span>
            }
            confirmButton={"Sí, eliminar"}
            courseId={data.id}
            deleteFunction={deleteCourse}
          />
        </Box>
      </Box>
      <CardMedia
        component="img"
        sx={{ width: "50%" }}
        image={randomImage}
        alt="Live from space album cover"
      />
    </Card>
  );
};

export { CourseCard };
