import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { AppContext } from "../Pages/AuthContext";
import { decodeJWT } from "../Components/Utilities";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import IndustrialSeminarsIcon from "../assets/logo_industrial_seminars.png";
import AdminUser from "../assets/admin_user.png";
import SellerUser from "../assets/seller_user.png";

const ROLES = {
  ADMIN: 1894,
  SELLER: 1527,
};

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#1976d2",
    },
  },
});

const Navbar = () => {
  const { userData, deleteDataUser } = useContext(AppContext);
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);

  let payload;
  let isAuthenticated;
  let isAdmin;
  let isSeller;
  let roleId;

  const adminPages = [];
  const adminSettings = ["Agregar cursos", "Agregar usuarios", "Cerrar sesión"];
  const sellerPages = [];
  const sellerSettings = ["Cerrar sesión"];

  let settings;
  let pages;

  if (userData.hasOwnProperty("access_token")) {
    payload = decodeJWT(userData.access_token);
    roleId = payload.role;
    isAuthenticated = roleId === 1527 || roleId === 1894;
    isAdmin = roleId === ROLES.ADMIN;
    isSeller = roleId === ROLES.SELLER;
    settings = isAdmin ? adminSettings : sellerSettings;
    pages = isAdmin ? adminPages : sellerPages;
  }

  const logout = () => {
    deleteDataUser();
    navigate("/");
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const translateText = (text) => {
    switch (text) {
      case "Agregar cursos":
        return "add_courses";
      case "Agregar usuarios":
        return "add_users";
      case "Cerrar sesión":
        return "logout";
      default:
        return text;
    }
  };

  const handleOptionSelected = (option) => {
    let optionSelected = translateText(option.target.textContent);

    switch (optionSelected) {
      case "add_courses":
        navigate("/add-course");
        break;
      case "add_users":
        navigate("/add-user");
        break;
      case "logout":
        logout();
        break;
      default:
        break;
    }

    setAnchorElUser(null);
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <AppBar
        className="app-bar-customized"
        position="static"
        color="primary"
        sx={{ height: "6vh" }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters className="toolbar-customized">
            <Box alignItems="center" justifyContent="center">
              <Box
                component="img"
                src={IndustrialSeminarsIcon}
                alt="Industrial Seminars Icon"
                sx={{
                  width: 46,
                  height: 25,
                  display: { xs: "none", md: "flex" },
                }}
              />
            </Box>
            <Typography
              variant="h6"
              onClick={() => navigate("/home")}
              noWrap
              component="a"
              sx={{
                marginLeft: 1,
                marginRight: 2,
                display: { xs: "none", md: "flex" },
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".2rem",
                color: "inherit",
                textDecoration: "none",
                cursor: "pointer",
              }}
            >
              Industrial Seminars CRM
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              {pages.map((page) => (
                <Button
                  key={page}
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: "white", display: "block" }}
                >
                  {page}
                </Button>
              ))}
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Abrir opciones">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar
                    alt="User Avatar"
                    src={isAdmin ? AdminUser : SellerUser}
                    sx={{ width: 56, height: 56 }}
                  />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem key={setting}>
                    <Typography
                      textAlign="center"
                      onClick={handleOptionSelected}
                    >
                      {setting}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </ThemeProvider>
  );
};

export { Navbar };
