import { useState, useEffect } from "react";
import Swal from "sweetalert2";

import { ButtonWithForm } from "./ButtonWithForm";
import { DeleteDialog } from "./DeleteDialog";

import random1 from "../../assets/tech_images/random1.jpeg";
import random2 from "../../assets/tech_images/random2.jpeg";
import random3 from "../../assets/tech_images/random3.jpeg";
import random4 from "../../assets/tech_images/random4.jpeg";
import random5 from "../../assets/tech_images/random5.jpeg";
import random6 from "../../assets/tech_images/random6.jpeg";
import random7 from "../../assets/tech_images/random7.jpeg";
import random8 from "../../assets/tech_images/random8.jpeg";
import random9 from "../../assets/tech_images/random9.jpeg";
import random10 from "../../assets/tech_images/random10.jpeg";
import random11 from "../../assets/tech_images/random11.jpeg";
import random12 from "../../assets/tech_images/random12.jpeg";
import random13 from "../../assets/tech_images/random13.jpeg";
import random14 from "../../assets/tech_images/random14.jpeg";
import random15 from "../../assets/tech_images/random15.jpeg";
import random16 from "../../assets/tech_images/random16.jpeg";
import random17 from "../../assets/tech_images/random17.jpeg";
import random18 from "../../assets/tech_images/random18.jpeg";
import random19 from "../../assets/tech_images/random19.jpeg";
import random20 from "../../assets/tech_images/random20.jpeg";
import random21 from "../../assets/tech_images/random21.jpeg";
import random22 from "../../assets/tech_images/random22.jpeg";
import random23 from "../../assets/tech_images/random23.jpeg";
import random24 from "../../assets/tech_images/random24.jpeg";
import random25 from "../../assets/tech_images/random25.jpeg";

import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import EditNoteIcon from "@mui/icons-material/EditNote";
import DeleteIcon from "@mui/icons-material/Delete";

let images = [
  random1,
  random2,
  random3,
  random4,
  random5,
  random6,
  random7,
  random8,
  random9,
  random10,
  random11,
  random12,
  random13,
  random14,
  random15,
  random16,
  random17,
  random18,
  random19,
  random20,
  random21,
  random22,
  random23,
  random24,
  random25,
];

const getRandomImage = () => {
  const randomIndex = Math.floor(Math.random() * images.length);
  return images[randomIndex];
};

const UserCard = ({ data, userData, userEmail, users, setUsers }) => {
  const [randomImage, setRandomImage] = useState("");

  const theme = useTheme();

  const getUserData = (userId) => {
    let response = users.find((user) => user.id === userId);
    return response;
  };

  const updateUser = async (bodyRequest) => {
    await fetch(`${import.meta.env.VITE_API_URL}/user/`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${userData.access_token}`,
      },
      body: JSON.stringify(bodyRequest),
    })
      .then((response) => response.json())
      .then((data) => {
        setUsers((prevUsers) =>
          prevUsers.map((user) =>
            user.id === data.updated_user.id ? data.updated_user : user
          )
        );
        Swal.fire({
          title: "Usuario actualizado",
          icon: "success",
        });
      })
      .catch((err) => {
        Swal.fire({
          title: "Error",
          icon: "error",
        });
      });
  };

  const deleteUser = async (userId) => {
    await fetch(`${import.meta.env.VITE_API_URL}/user/${userId}`, {
      method: "DELETE",
      credentials: "include",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${userData.access_token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setUsers((prevUsers) => prevUsers.filter((user) => user.id !== userId));

        Swal.fire({
          title: "Usuario eliminado",
          icon: "success",
        });
      })
      .catch((err) => {
        Swal.fire({
          title: "Error",
          icon: "error",
        });
      });
  };

  const translateText = (text) => {
    switch (text) {
      case "name":
        return "Nombre del usuario";
      case "email":
        return "Email";
      case "role":
        return "Puesto";
      case "updated_at":
        return "Fecha de actualización";
      case "updated_by":
        return "Actualizado por";
      default:
        return text;
    }
  };

  const getImageUrl = () => {
    return "https://picsum.photos/350/150";
  };

  useEffect(() => {
    let image = getRandomImage();
    setRandomImage(image);
  }, []);

  return (
    <Card
      sx={{
        maxWidth: 270,
        minHeight: 320,
        backgroundColor: "#e8c2a1",
        borderRadius: 8,
        borderStyle: "solid",
      }}
    >
      <CardMedia
        component="img"
        height="140"
        image={getImageUrl()}
        alt="profile image"
        sx={{
          margin: "20px auto",
          width: 140,
          height: 140,
          borderRadius: "50%",
          objectFit: "cover",
        }}
      />
      <CardContent sx={{ textAlign: "center" }}>
        <Typography
          gutterBottom
          variant="h5"
          component="div"
          alignContent="center"
        >
          {data.name}
        </Typography>
        <Typography variant="subtitle1" color="text.secondary">
          {data.email}
        </Typography>
        <Typography variant="subtitle2" color="text.secondary">
          {data.role === 1527
            ? "Vendedor"
            : data.role === 1685
            ? "Promotor"
            : "Administrador"}
        </Typography>
      </CardContent>
      <Box sx={{ display: "flex", alignItems: "center", pl: 1, pb: 1 }}>
        <ButtonWithForm
          mainButton={<EditNoteIcon sx={{ height: 38, width: 38 }} />}
          formFields={[
            "name",
            "email",
            "password",
            "role",
            "updated_at",
            "updated_by",
          ]}
          userData={getUserData(data.id)}
          userEmail={userEmail}
          translation={translateText}
          submitFunction={updateUser}
          formTitle={"Editar Usuario"}
          formContent={"Puedes modificar los datos del usuario seleccionado"}
          confirmButton={"Guardar Cambios"}
        />
        <DeleteDialog
          mainButton={
            <DeleteIcon sx={{ height: 38, width: 38, color: "#f45757" }} />
          }
          formTitle={"Eliminar Usuario"}
          formContent={
            <span>
              ¿Estás seguro de que deseas eliminar al usuario <b>{data.name}</b>
              ?
            </span>
          }
          confirmButton={"Sí, eliminar"}
          userId={data.id}
          deleteFunction={deleteUser}
        />
      </Box>
    </Card>
  );
};

export { UserCard };
