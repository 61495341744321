import { useNavigate } from 'react-router-dom';
import './style.css'

const NotFound = () => {
  const navigate = useNavigate();

  const sendToHome = () => {
    navigate("/");
  }

  return (
    <div id="notfound">
      <div className="notfound">
        <div className="notfound-404">
          <h1>404</h1>
          <h2>Page not found</h2>
        </div>
        <a onClick={sendToHome} style={{ cursor: "pointer" }} >Iniciar sesión</a>
      </div>
    </div>
  );
}

export { NotFound }