import { Fragment, useState } from "react";

import { CourseCard } from "./CourseCard";

import { Grid, Stack, Button, Box } from "@mui/material";

const Carousel = ({
  elements,
  setElements,
  userData,
  userEmail,
  formData,
  sellerUsers,
}) => {
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 9;

  const handleNext = () => {
    if (currentPage < Math.floor(elements.length / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevious = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const currentItems = elements.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  return (
    <Fragment>
      <div style={{ height: "76%" }}>
        <Grid container spacing={2} columns={13} marginTop={7}>
          {currentItems.map((course) => (
            <Grid item xs={4} key={course.id}>
              <CourseCard
                data={course}
                userData={userData}
                userEmail={userEmail}
                courses={elements}
                setCourses={setElements}
                formData={formData}
                sellerUsers={sellerUsers}
              />
            </Grid>
          ))}
        </Grid>
      </div>
      <Box mt={6}>
        <Stack direction="row" spacing={2} marginLeft={"38%"}>
          <Button
            variant="contained"
            color="secondary"
            onClick={handlePrevious}
            disabled={currentPage === 0}
          >
            Anterior
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleNext}
            disabled={
              currentPage >= Math.floor(elements.length / itemsPerPage) ||
              (elements.length % itemsPerPage === 0 &&
                currentPage === Math.floor(elements.length / itemsPerPage) - 1)
            }
          >
            Siguiente
          </Button>
        </Stack>
      </Box>
    </Fragment>
  );
};

export { Carousel };
