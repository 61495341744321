import { Fragment, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MenuItem from "@mui/material/MenuItem";

import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const ButtonWithForm = ({
  mainButton,
  formFields,
  userData,
  userEmail,
  translation,
  submitFunction,
  formTitle,
  formContent,
  confirmButton,
}) => {
  const [open, setOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [role, setRole] = useState("");

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    setRole(event.target.value);
  };

  return (
    <Fragment>
      <IconButton aria-label="edit" onClick={handleClickOpen}>
        {mainButton}
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());

            let bodyRequest = {};

            formFields.forEach((field) => {
              if (field === "role") {
                bodyRequest[field] = parseInt(formJson[field]);
              } else if (field !== "updated_at" && field !== "updated_by") {
                bodyRequest[field] = formJson[field];
              }
            });

            bodyRequest["user_id"] = userData.id;
            bodyRequest["user_email"] = userEmail;

            submitFunction(bodyRequest);
            handleClose();
          },
        }}
      >
        <DialogTitle color={"secondary"}>{formTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText marginBottom={2}>{formContent}</DialogContentText>
          {Object.entries(userData).map(([key, label]) =>
            formFields.includes(key) && key != "role" ? (
              <TextField
                key={key}
                disabled={key === "updated_at" || key === "updated_by" ? true : false}
                fullWidth
                color="secondary"
                margin="dense"
                variant="outlined"
                name={key}
                label={translation(key)}
                defaultValue={label}
                type="text"
              />
            ) : key == "role" ? (
              <Fragment key={key}>
                <TextField
                  id="outlined-select-currency"
                  select
                  fullWidth
                  name={key}
                  label={translation(key)}
                  color="secondary"
                  margin="dense"
                  variant="outlined"
                  value={role || label}
                  onChange={handleChange}
                >
                  <MenuItem value={1527}>Vendedor</MenuItem>
                  <MenuItem value={1685}>Promotor</MenuItem>
                  <MenuItem value={1894}>Administrador</MenuItem>
                </TextField>
              </Fragment>
            ) : null
          )}
          <TextField
            fullWidth
            color="secondary"
            margin="dense"
            variant="outlined"
            name="password"
            label="Nueva Contraseña"
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button color="secondary" variant="contained" type="submit">
            {confirmButton}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export { ButtonWithForm };
